import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


//import logo from './logo.svg';
import './App.css';
import { useHistory } from "react-router-dom";
import {validateDate} from './functions.js';
//import { Router, Route, Switch } from 'react-router';
import { BrowserRouter as Router,
		Route,
		Redirect,
		Switch,
		Link } from 'react-router-dom';
//import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import ReCAPTCHA from "react-google-recaptcha";
const BASE="https://vinograd-loza.ru";
function jsonize(form){
	var res = {};
	for(var i=0;i<form.elements.length;++i){
		var el = form.elements[i];
		var s = el.name;
		if(s){
			if(s.endsWith("[]")){
				s = s.substring(0,s.length-2);
				if(s in res){
					res[s].push(el.value);
				}else{
					res[s]=[el.value];
				}
			}else{
				res[s] = el.value;
			}
		}
	}
	return res;
}

function Profile(props) {
	let history = useHistory();

	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState({
		"card":"",
		"phone":"",

		"locality": "",

		"birthday": "",
		"email":"",
		"name":"",
		"surname":"",
		"patronymic":"",
		"sex":"",
		"civilStatus":"",
		"childCount":"",
	});


	function onSubmit(e){
		e.preventDefault();
		var errorBox = document.getElementById("errorBox");
		errorBox.style.display="none";
		if(!e.target.checkValidity()) return; // DO display error?
		//var data = jsonize(e.target);
		var data = {childDates:[]};
		for(var a in userData){
			console.log(a);
			if(a.startsWith("childDate")){
				data.childDates.push(userData[a]);
			}else{
				data[a] = userData[a];
			}
		}
		console.log(data);
		var oData=data;

		fetch(BASE+'/api/profile', {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify(data)
		})
		.then(function (res) {
			return res.json()
		})
		.then(function (data) {
			var errorBox = document.getElementById("errorBox");
			if(data.error){
				errorBox.style.display="block";
				errorBox.innerHTML=data.error;
			}
				console.log("got answer");
			if(data.redirect){
				// /login or /
				console.log("got redirect");
				console.log(data.redirect);
				oData.completeness=data.completeness;
				props.setUserData(oData);
				history.push(data.redirect);

			}
		}).catch(function (err){
				console.log("got error");
			console.log(err);
		});
	}
	useEffect(() => {

		fetch(BASE+'/api/profile',{
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
				'Accept': 'application/json',
			}),
		})
		.then(function (res) {
			return res.json()
		})
		.then(function (data) {
			console.log(data);
			if(data.childDates){
				for(var i=0;i<data.childDates.length;++i){
					var k = "childDate"+i;
					var v = data.childDates[i];
					data[k]=v;
				}
				delete data['childDates'];
			}

			var ndata = Object.assign({}, userData, data);
			setUserData(ndata);
			setLoading(false);
		}).catch(function (err){
			console.log(err);
		});

		
    }, []);
	function getCard(){
		return localStorage.getItem('cardNumber');
		//return localStorage.getItem('cardPhone');
	}
	function getCardType(){
		return userData.card.startsWith("255") ? "9pravil" : "vinograd";
	}
	var isSafari = true;//Зоопарк календарей офф. Сафари он
	//navigator.userAgent.indexOf("AppleWebKit") > -1 && navigator.userAgent.indexOf("Chrome") == -1;
	function focusDate(target){
		if(isSafari)return;
		target.type="date";
	}
	function blurDate(target){
		if(isSafari)return;
		target.type="text";
	}
	function childrenList(){
		if(!userData.childCount) return null;
		var res = [];
		for(var i=0;i<userData.childCount;++i){
			var cn="childDate"+i;
			res.push(<input key={i} name={cn} placeholder="Дата рождения ребёнка" className="form-control mb-4" 
				onFocus={(e)=>focusDate(e.currentTarget)} onBlur={(e)=>blurDate(e.currentTarget)}
				onChange={sUD} value={gUD(cn)}/>);
		}
		return res;
	}
	function sS(key, value){
		console.log(userData);
		setUserData(oData => {
		  const nData = Object.assign({}, oData);  // creating copy of state variable jasper
		  nData[key] = value;
		  console.log(key+" => "+value)
		  return nData;
		});
		console.log(userData);
	}
	function sUD(e){//universal onChange
		sS(e.target.name, e.target.value);
	}
	function sUDbdate(e){
		validateDate(e);
		sS(e.target.name, e.target.value);
	}
	function gUD(cn){//universal value
		if(cn in userData)
			return userData[cn];
		return "";
	}
	if(loading){
		return(
		<form id="profileForm" className="loginform border border-light p-5" onSubmit={onSubmit}>
			<p className="h4 mb-4 text-center">Личные данные</p>
			<p>Загрузка...</p>
		</form>
		);
	}
	function getClasses(val){
		if(val==="")
			return "form-control mb-4 emptyField";
		return "form-control mb-4";
	}
	if(!props.getLoggedIn()) return(
		<Redirect to="/login"/>
	);

	return (
		<form id="profileForm" className="loginform border border-light p-5" onSubmit={onSubmit}>
			<p className="h4 mb-4 text-center">Личные данные</p>
			<input type="text" name="card" pattern="\d*" maxLength="20" className="form-control mb-4" placeholder="Номер карты*" readOnly value={userData.card} onChange={sUD}/>

    <input type="text" name="phone" pattern="\d*" maxLength="11" className="form-control mb-4"  placeholder="Телефон*" disabled value={userData.phone} readOnly onChange={sUD}/>
    <input type="email" name="email" className="form-control mb-4"  placeholder="E-mail" value={userData.email} onChange={sUD}/>
    <input type="text" name="surname" className="form-control mb-4"  placeholder="Фамилия*" required value={userData.surname} onChange={sUD}/>
    <input type="text" name="name" className="form-control mb-4"  placeholder="Имя*" required value={userData.name} onChange={sUD}/>
    <input type="text" name="patronymic" className="form-control mb-4"  placeholder="Отчество*" required value={userData.patronymic} onChange={sUD}/>
        <select name="sex" className="form-control mb-4" required value={userData.sex} onChange={sUD}>
                <option value="" disabled>Пол*
				</option>
                <option value="male">Мужской
				</option>
                <option value="female">Женский
				</option>
        </select>
			<input type="text" name="birthday" className="form-control mb-4"  placeholder={isSafari?"Дата рождения* дд.мм.гггг":"Дата рождения*"}
				onFocus={(e)=>focusDate(e.currentTarget)} onBlur={(e)=>blurDate(e.currentTarget)}
				required value={userData.birthday} onChange={sUDbdate}/>
        <select name="locality" className="form-control mb-4" {...(getCardType()=='vinograd'? {"required":""}:{})} value={userData.locality} onChange={sUD}>
                <option value="" disabled>Населённый пункт{getCardType()=='vinograd'? "*":""}
				</option>
                <option value="Иркутск">Иркутск
				</option>
                <option value="Улан-Удэ">Улан-Удэ
				</option>
                <option value="Братск">Братск
				</option>
                <option value="Ангарск">Ангарск
				</option>
                <option value="Шелехов">Шелехов
				</option>
                <option value="Усолье-Сибирское">Усолье-Сибирское
				</option>
                <option value="Хомутово">Хомутово
				</option>
                <option value="Маркова">Маркова
				</option>
                <option value="Молодёжный">Молодёжный
				</option>
                <option value="Усть-Ордынский">Усть-Ордынский
				</option>
                <option value="Другой">Другой
				</option>
        </select>
			<div id="errorBox" className="alert alert-danger" style={{display: "none", marginTop: "10px"}}></div>
			<button className="btn btn-danger btn-block my-4">Сохранить</button>
		</form>	
	);
}

export default Profile;
