import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal,Button } from 'react-bootstrap';

import vlb_card from './images/vlb_card.png';

//import logo from './logo.svg';
import './App.css';
import { useHistory } from "react-router-dom";
import {validatePhone} from './functions.js';
//import { Router, Route, Switch } from 'react-router';
import { BrowserRouter as Router,
		Route,
		Redirect,
		Switch,
		Link } from 'react-router-dom';
const BASE="https://vinograd-loza.ru";

function Register(props) {
	let history = useHistory();
	const [showModal, setShowModal] = React.useState(false);
	const [showPers, setShowPers] = React.useState(false);
	const [codePrefix, setCodePrefix] = React.useState(null);

	var card;
	var phone;
	var phoneCode;
	function getPrefix(){
		return "+7(***)*"+codePrefix+"****";
	}
	function handleClose(){// modal close = go to profile
		setShowModal(false);
		props.setLoggedIn(true);
		//history.push('/profile');
	}

	function getCode(e){
		var phone = document.getElementById("phone").value;
		var statusBox = document.getElementById("statusBox");
		var errorBox = document.getElementById("errorBox");
		errorBox.style.display="none";
		var but = document.getElementById("getPhoneCode");
		but.disabled=true;
		//TODO settimeout for second call
		
		fetch(BASE+'/api/phone/callnumb', {
			method: 'POST',
			headers: {'Content-Type':'application/x-www-form-urlencoded'},
			body: "phone="+phone
		})
		.then(function (res) {
			return res.json()
		})
		.then(function (data) {
			console.log(data);
			but.disabled=false;
			if(data.error){
				errorBox.style.display="block";
				errorBox.innerHTML=data.error;
			}else if(data.info){
				statusBox.style.display="block";
				statusBox.innerHTML=data.info;

				if(data.prefix)
					setCodePrefix(data.prefix);
			}
		}).catch(function (err){
			console.log(err);
			but.disabled=false;
		});
	}

	function onSubmit(e){
		e.preventDefault();
		console.log('submitting');
		var errorBox = document.getElementById("errorBox");
		errorBox.style.display="none";
		if( ! e.target.accRules.checked ){
			errorBox.style.display="block";
			errorBox.innerHTML="Подтвердите, что согласны с правилами обработки персональных данных";
			return;
		}
		if( e.target.card.value.length != 13){
			errorBox.style.display="block";
			errorBox.innerHTML="Номер карты состоит из 13 цифр. см рисунок";
			return;
		}

		fetch(BASE+'/api/card/register', {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify({
				"card": e.target.card.value,
				"phone": e.target.phone.value,
				//"code": codePrefix+e.target.phoneCode.value // paranoidal OFF
				"code": e.target.phoneCode.value //4digit
			})
		})
		.then(function (res) {
                return res.json()
            })
            .then(function (data) {
				if(data.error){
					errorBox.style.display="block";
					errorBox.innerHTML=data.error;
				}else{
					if(!data.token){
						errorBox.innerHTML='Не удалось авторизоваться';
					}else{
						localStorage.setItem("cardAuthToken",data.token);
						localStorage.setItem("cardNumber",data.number);
						localStorage.setItem("cardPhone",data.phone);
						console.log("Auth OK");
						console.log("Showing modal... ");
						setShowModal(true);
						console.log("Showed modal... ");
						//history.push('/profile');
					}
				}
            }).catch(function (err) {
                errorBox.innerHTML=err;
            });

	};

	if(props.getLoggedIn()){
		return <Redirect to="/"/>
	}
	return (
		<>
		<form className="loginform border border-light p-5" onSubmit={onSubmit}>
			<p className="h4 mb-4 text-center">Регистрация Карты покупателя</p>
			<img className="sample_card" src={vlb_card}/>
			<input type="text" name="card" ref={card} pattern="\d*" maxLength="13" className="form-control mb-4" placeholder="Номер карты* ( под штрихкодом, например: 2530123456789)"/>
			<input type="text" name="phone" ref={phone} pattern="\d*" maxLength="11" className="form-control mb-4" placeholder="Ваш телефон* ( например: 79876543210)" id="phone" onChange={validatePhone}/>
			<input type="text" name="phoneCode" ref={phoneCode} pattern="\d*" maxLength="4" className="form-control mb-4" placeholder="Код подтверждения*" id="phoneCode" title={getPrefix()}/>
			<button id="getPhoneCode" type="button" className="btn btn-danger " onClick={getCode}>Получить код</button>
			<div id="statusBox" className="alert alert-info" style={{display: "none"}}>
			</div>
			<div id="infoBox" className="alert alert-info">
				Код из 4х цифр отправляется СМС-сообщением
			</div>
			<input type="checkbox" name="accRules" /><label htmlFor="accRules" style={{paddingLeft: "10px"}}>Согласен с <a href="#" onClick={()=>setShowPers(true)}>правилами</a> обработки персональных данных</label>
			<div id="errorBox" className="alert alert-danger" style={{display: "none", marginTop: "10px"}}></div>
			<button className="btn btn-danger btn-block my-4">Регистрация</button>
		</form>	
		<Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Поздравляем!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
			<div>Карта привязана к вашему телефону.</div>
			<div>Заполните анкету, чтобы получить доступ в Личный Кабинет.</div>
		</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Продолжить
          </Button>
        </Modal.Footer>
      </Modal>
		<Modal show={showPers} onHide={()=>setShowPers(false)} dialogClassName="modal960">
        <Modal.Header closeButton>
          <Modal.Title>Согласие на обработку персональных данных</Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<p>
Настоящим подтверждаю, что свободно, своей волей и в своем интересе даю ООО «Ника» (ИНН 3849067836), расположенному по адресу 664007, г.Иркутск, ул.Октябрьской революции, д.1, пом.19 (далее - Оператор), письменное согласие на обработку Персональных данных, полученных Оператором в связи с заключением мною договора на участие в Программе лояльности «LOYA» с использованием сайта https://vinograd-loza.ru/
		</p>
		<p>
Согласие распространяется на обработку таких данных, как фамилия, имя, отчество, дата и место рождения, пол, семейное положение, гражданство, сведения о наличии, возрасте, дате рождения детей, место жительства, наименовании магазинов, которые я посещаю, перечень совершенных покупок с использованием карты покупателя, включая наименования магазинов, товаров и цены, номер карты, статус карты, номер сотового телефона, электронной почты (e-mail). Согласие распространяется на обработку указанных Персональных данных путем совершения с Персональными данными любого действия (операции) или совокупности действий (операций) с использованием средств автоматизации или без таковых, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение Персональных данных.
		</p>
		<p>
Настоящее согласие дается в целях исполнения заключенного Оператором со мной договора на участие в программе лояльности «ЛОЯ», в том числе для обеспечения пользования функционалом сайта https://vinograd-loza.ru/, активации и использования карты покупателя, получения эффективной клиентской и технической поддержки по телефону и электронной почте, реализации маркетинговых мероприятий Партнерами Оператора по программе лояльности, предоставления мне информации об акциях Оператора и/или Партнеров программы, а так же рекламной рассылки указанных лиц, в том числе по телефону и/или по электронной почте.
		</p>
		<p>
Настоящее согласие действует бессрочно, но может быть отозвано мной в любой момент путем направления соответствующего письменного уведомления Оператору на почтовый адрес: 664007, г.Иркутск, ул.Октябрьской революции, д.1, пом.19
		</p>
		</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

	  </>
	);
}

export default Register;
