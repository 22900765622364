import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

//import logo from './logo.svg';
import addAndroid from './images/wallet_android.png';
import addIos from './images/wallet_ios.png';
import './App.css';
//import { Router, Route, Switch } from 'react-router';
import { BrowserRouter as Router,
		Route,
		Redirect,
		Switch,
		useHistory,
		Link } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3'

const BASE="https://vinograd-loza.ru";

function Home(props){
	let history = useHistory();
	const [balance, setBalance] = useState(0);
	const [refresh, setRefresh] = useState(0);
	const [transactions, setTransactions] = useState(null);
	const [couponsTitle, setCouponsTitle] = useState(null);
	const [coupons, setCoupons] = useState(null);
	const [cheque, setCheque] = useState(null);
	const [chequeId, setChequeId] = useState(null);

	//for virtual card
	const [cards, setCards] = useState({});
	async function loadCards(){
		var res = await fetch(BASE+'/api/wallet/cards?cardtype=vinograd',{
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
				'Accept': 'application/json',
			}),
		});
		res = await res.json();
		console.log(res);
		setCards(res);
		if(res.cards.length==0)
			cloneCard();

	}
	async function cloneCard(){
		var res = await fetch(BASE+'/api/wallet/card_clone?cardtype=vinograd',{
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
				'Accept': 'application/json',
			}),
		});
		res = await res.json();
		console.log(res);
		//loadCards();
		if(res.cards){
			setCards(res)
			//window.open(res.cards[0].url);
		}
	}
	function openCard(){
		window.open(cards.cards[0].url);
	}

	const [ops, setOps] = useState([]);
	function getCheque(){
		var res = [];
		cheque.forEach(c=>{
			res.push(
				<tr key={c.position}>
					<td>{c.position}</td>
					<td>{c.name}</td>
					<td>{c.quantity}</td>
					<td>{c.price}</td>
					<td>{c.total}</td>
					<td>{c.discount}</td>
					<td>-{c.usedBonus}</td>
				</tr>
			);
		});
		return res;
	}
	function getOps(){
		var res = [];
		if(transactions){
			transactions.forEach(e =>{
				res.push(
					<tr key={e.id} onClick={(ev) => loadCheque(e.id, ev)}>
					<td>{e.date}</td>
					<td>{e.mName}</td>
					<td>{e.paid}</td>
					<td>{e.discount}</td>
					</tr>
				);

				if(cheque && e.id==chequeId){
					res.push(
						<tr key={-e.id}>
							<td colspan="4">
								<table className="cheque">
									<thead>
										<th>№</th>
										<th>название</th>
										<th>к-во</th>
										<th>цена</th>
										<th>сумма</th>
										<th>скидка</th>
										<th>бонусы</th>
									</thead>
									<tbody>
										{getCheque()}
									</tbody>
								</table>
							</td>
						</tr>
					);
				}
			});
		}
		return res;
	}
	function getCoupons(){
		var res = [];
		if(coupons){
			if(coupons.length>0){
				res.push(
					<h4>{couponsTitle} купоны:</h4>
				)
			}
			coupons.forEach(e =>{
				res.push(
					<div class="alert alert-danger">{e}</div>
				);
			});
		}
		return res;
	}
	async function loadBalance(){
		var res = await fetch(BASE+'/api/card/balance',{
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
				'Accept': 'application/json',
			}),
		});
		res = await res.json();
		console.log("BALANCE");
		console.log(res);
		console.log("BALANCE");
		setBalance(res.balance);
	}
	async function loadCheque(chid, e){
		var res = await fetch(BASE+'/api/card/cheque?id='+chid,{
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
				'Accept': 'application/json',
			}),
		});
		//then
		res = await res.json();
		setCheque(res['data']);
		setChequeId(chid);
		console.log(res["data"]);

	}
	function isDataFull(ud){
		if(ud.name && ud.surname && ud.birthday && ud.sex && ud.locality){
			return true;
		}
		return false;
	}

	useEffect(() => {
		if(props.getLoggedIn()){
			setCards({loading:true})
			loadCards();

			console.log("requesting");
			(async function(){
			try{
				var res = await fetch(BASE+'/api/card/transactions',{
					method: 'GET',
					headers: new Headers({
						'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
						'Accept': 'application/json',
					}),
				});
				//then
				res = await res.json();
				setTransactions(res['data']);
				console.log(res["data"]);


			}catch(e){
				console.log(e);
			}
			})();
			(async function(){
			try{
				var res = await fetch(BASE+'/api/card/coupons',{
					method: 'GET',
					headers: new Headers({
						'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
						'Accept': 'application/json',
					}),
				});
				//then
				res = await res.json();
				setCoupons(res['data']);
				setCouponsTitle(res['title']);
				console.log(res["data"]);

			}catch(e){
				console.log(e);
			}
			})();

			loadBalance();

		}
    }, []);

	if(!props.getLoggedIn()) return(
		<Redirect to="/login"/>
	);
	if(props.userData.loading) return(
		<div className="main">
		<p>Загрузка...</p>
		</div>
	)
	if(!isDataFull(props.userData)){ //0.5?
		return(
		<Redirect to="/profile" />
		)
	}

	return (
		<div className="main">
			<p>Здравствуйте, {props.userData.name} {props.userData.patronymic}.</p>
			<p>Cумма ваших покупок <b>{props.userData.clv}</b> рублей.</p>
			<p>Ваша Скидка: <b>{
				cards.cards && cards.cards.length>0 ? (
				cards.cards[0].discount
				):(
				<>...</>)

				} </b>
				Бонусы: {balance}
			</p>
		<div className="alert alert-danger">
			Телефон горячей линии 8(3952)65-56-77 (будние дни с 10 до 18)
		</div>
		<div className="alert alert-light">
			{!cards.cards ?(
				<>Загрузка...</>
			): cards.cards.length>0 ? (
				<>
				Вы можете добавить карту в Кошелёк на вашем телефоне iPhone/Android.
				<br/>
				<input type="image" src={addAndroid} onClick={openCard} className="wallet_button"/>
				<input type="image" src={addIos} onClick={openCard} className="wallet_button"/>
				</>
			):(
				<>
				Электронная карта скоро будет готова...
				</>
			)}
		</div>
		<div>
			{getCoupons()}
		</div>
		<div className="alert alert-light">
			<table className="transactions">
			<thead>
				<tr>
					<th>Время</th>
					<th>Магазин</th>
					<th>Сумма (к оплате)</th>
					<th>Скидка</th>
				</tr>
			</thead>
			<tbody>
				{getOps()}
			</tbody>
			</table>
		</div>

		</div>	
	);
}

export default Home;
