


export function validatePhone(e){
	var s = e.target.value;
	if(s=="+7") s="7";
	if(s.startsWith("+") && s.length>1)
		s = s.substr(1);
	if(s.startsWith("8"))
		s = "7"+s.substr(1);
	e.target.value = s;
}

export function validateDate(e){
	var s = e.target.value;
	s = s.replace(/[^0-9.]/g, '');
	if(s.length>1 && s[s.length-1]=='.'){
		//manual points for short numbers 1.1.1999
		if(s.length==2)
			s="0"+s;
		if(s.length==5){
			s=s.replace(".","");
			if(s.length>2){
				s=s[0]+s[1]+".0"+s.substr(2);
			}
		}
			
	}
	//first point
	if(s.length==3 && s.indexOf(".")==-1)
		s=s[0]+s[1]+"."+s[2];
	//second point
	if(s.length==6 && s[s.length-1]!=".")
		s=s.substr(0,5)+"."+s[5];

	e.target.value = s;
}
