import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal,Button } from 'react-bootstrap';

//import logo from './logo.svg';
import './App.css';
//import { Router, Route, Switch } from 'react-router';
import { BrowserRouter as Router,
		Route,
		Redirect,
		Switch,
		Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const BASE="https://vinograd-loza.ru";

function Feedback(props){
	let history = useHistory();
	const recaptchaRef = React.createRef();
	const [showModal, setShowModal] = React.useState(false);

	function handleClose(){// modal close = go to profile
		setShowModal(false);
		history.push('/');
	}
	useEffect(() => {
    }, []);
	const onChange = (value) => {
    	// Here you will get the final recaptchaToken!!!
		console.log(value, "<= your recaptcha value");
	};
	function onSubmit(e){
		e.preventDefault();
		const recap = recaptchaRef.current.getValue();

		try{
			(async function(){
				var res = await fetch(BASE+'/api/feedback',{
					method: 'POST',
					headers: {
						'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"email": e.target.email.value,
						"text": e.target.text.value,
						"recap": recap,
					})
				});
				if(res.status==200){
					res = await res.json();
					if("error" in res){
						document.getElementById('errorBox').value = res["error"];
						document.getElementById('errorBox').style.display = "inline";
						//recaptchaRef.reset();
					}
					if("message" in res){
						console.log(res["message"]);
						setShowModal(true);
						//TODO popup, then redirect
					}
				}else{
					throw "ошибка сервера "+res.status;
				}
				
			})();
		}catch(e){
			//recaptchaRef.reset();
			document.getElementById('errorBox').value = "Ошибка: "+e;
			document.getElementById('errorBox').style.display = "inline";
		}

	}

	return (
		<form className="loginform border border-light p-5" onSubmit={onSubmit}>
			<p className="h4 mb-4 text-center">Обратная связь</p>
			<input type="email" name="email" className="form-control mb-4" placeholder="E-mail*"/>
			<textarea type="text" name="text" maxLength="2000" className="form-control mb-4" placeholder="Текст обращения*" required></textarea>

			<ReCAPTCHA
				ref={recaptchaRef}
				sitekey="6LcSkcAUAAAAANUrifM4Dofajf3PFk_q-0ecKfwK"
				onChange={onChange}
				hl="ru"
			/>
			<div id="errorBox" className="alert alert-danger" style={{display: "none", marginTop: "10px"}}></div>
			<button className="btn btn-danger btn-block my-4">Отправить</button>
		<Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Сообщение отправлено!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
			<div>Ответ будет отправлен вам на email</div>
		</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
		</form>	
	);
}

export default Feedback;
