import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//import logo from './logo.svg';
import './App.css';
import { useHistory } from "react-router-dom";
//import { Router, Route, Switch } from 'react-router';
import { BrowserRouter as Router,
		Route,
		Redirect,
		Switch,
		Link } from 'react-router-dom';
import {validatePhone} from './functions.js';

//import ReCAPTCHA from "react-google-recaptcha";
const BASE="https://vinograd-loza.ru";

function LoginCard(props){
	let history = useHistory();
	function onSubmit(e){
		e.preventDefault();
		console.log('submitting');
		var errorBox = document.getElementById("errorBox");
		errorBox.style.display="none";

		fetch(BASE+'/api/card/login', {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify({
				"card": e.target.card.value,
				"cardtype": "vinograd",
				"phone": e.target.phone.value,
			})
		})
		.then(function (res) {
                return res.json()
            })
            .then(function (data) {
				console.log("login:");
				console.log(data);
				if(data.error){
					errorBox.style.display="block";
					errorBox.innerHTML=data.error;
				}else{
					if(!data.token){
						errorBox.innerHTML='Не удалось авторизоваться';
					}else{
						localStorage.setItem("cardAuthToken",data.token);
						localStorage.setItem("cardNumber",data.number);
						localStorage.setItem("cardPhone",data.phone);
						props.setLoggedIn(true);
						history.push(data.redirect);
					}
				}
            }).catch(function (err) {
                errorBox.innerHTML=err;
            });

	}
	if(props.getLoggedIn()){
		return <Redirect to="/"/>
	}
	return (
		<form className="loginform border border-light p-5" onSubmit={onSubmit}>
			<p className="h4 mb-4 text-center">Вход в личный кабинет</p>
			<input type="text" name="card" pattern="\d*" maxLength="13" className="form-control mb-4" placeholder="Номер карты* (например: 2550123456789)"/>
			<input type="text" name="phone" pattern="\d*" maxLength="11" className="form-control mb-4" placeholder="Телефон* (например: 79876543210)" onChange={validatePhone}/>
			<div id="errorBox" className="alert alert-danger" style={{display: "none", marginTop: "10px"}}></div>
			<button className="btn btn-danger btn-block my-4">Войти</button>
		</form>	
	);
}

export default LoginCard;
