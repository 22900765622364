import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//import logo from './logo.svg';
import './App.css';
import { useHistory } from "react-router-dom";
//import { Router, Route, Switch } from 'react-router';
import { BrowserRouter as Router,
		Route,
		Redirect,
		Switch,
		Link } from 'react-router-dom';
import {validatePhone} from './functions.js';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//import ReCAPTCHA from "react-google-recaptcha";
const BASE="https://vinograd-loza.ru";

function Login(props){
	let history = useHistory();
	const [codePrefix, setCodePrefix] = React.useState(null);
	var phone;
	var phoneCode;
	var currentTab = "phone_card";
	function getPrefix(){
		return "+7(***)*"+codePrefix+"****";
	}
	function getCode(e){
		var phone = document.getElementById("phone").value;
		var statusBox = document.getElementById("statusBox");
		var errorBox = document.getElementById("errorBox");
		errorBox.style.display="none";
		var but = document.getElementById("getPhoneCode");
		but.disabled=true;
		//TODO settimeout for second call
		
		fetch(BASE+'/api/phone/callnumb', {
			method: 'POST',
			headers: {'Content-Type':'application/x-www-form-urlencoded'},
			body: "phone="+phone
		})
		.then(function (res) {
			return res.json()
		})
		.then(function (data) {
			console.log(data);
			but.disabled=false;
			if(data.error){
				errorBox.style.display="block";
				errorBox.innerHTML=data.error;
			}else if(data.info){
				statusBox.style.display="block";
				statusBox.innerHTML=data.info;

				if(data.prefix)
					setCodePrefix(data.prefix);
			}
		}).catch(function (err){
			console.log(err);
			but.disabled=false;
		});
	}
	function tabChanged(key){
		currentTab = key;
	}

	function onSubmit(e){
		e.preventDefault();
		console.log('submitting');
		var errorBox = document.getElementById("errorBox");
		errorBox.style.display="none";

		var loginUrl = BASE + "/api/card/login_phone";
		var loginData = {
			"phone": e.target.phone.value,
			"cardtype": "vinograd",
		};
		if(currentTab == "phone_code"){
			loginData['code'] = e.target.phoneCode.value; //4digit
			loginUrl = BASE + "/api/card/login_phone";
		}else{
			loginUrl = BASE + "/api/card/login";
			loginData['card'] = e.target.card.value; //4digit
		}

		fetch( loginUrl, {
			method: 'POST',
			headers: {'Content-Type':'application/json'},
			body: JSON.stringify(loginData)
		})
		.then(function (res) {
				return res.json()
			})
			.then(function (data) {
				console.log("login:");
				console.log(data);
				if(data.error){
					errorBox.style.display="block";
					errorBox.innerHTML=data.error;
				}else{
					if(!data.token){
						errorBox.innerHTML='Не удалось авторизоваться';
					}else{
						localStorage.setItem("cardAuthToken",data.token);
						localStorage.setItem("cardNumber",data.number);
						localStorage.setItem("cardPhone",data.phone);
						props.setLoggedIn(true);
						history.push(data.redirect);
					}
				}
			}).catch(function (err) {
				errorBox.innerHTML=err;
			});
	}
	if(props.getLoggedIn()){
		return <Redirect to="/"/>
	}
	return (
		<form className="loginform border border-light p-5" onSubmit={onSubmit}>
			<p className="h4 mb-4 text-center">Вход в личный кабинет</p>
			<input type="text" name="phone" ref={phone} pattern="\d*" maxLength="11" className="form-control mb-4" placeholder="Ваш телефон* ( например: 79876543210)" id="phone" onChange={validatePhone}/>
			<Tabs defaultActiveKey={currentTab} onSelect={tabChanged} >
			<Tab eventKey="phone_card" title="По номеру карты">
				<input type="text" name="card" ref={phone} pattern="\d*" maxLength="13" className="form-control mb-4" placeholder="Номер карты* (например: 2530123456789)"/>
			</Tab>
			<Tab eventKey="phone_code" title="По коду из смс">
				<input type="text" name="phoneCode" ref={phoneCode} pattern="\d*" maxLength="4" className="form-control mb-4" placeholder="Код подтверждения*" id="phoneCode" title={getPrefix()}/>
				<button id="getPhoneCode" type="button" className="btn btn-danger " onClick={getCode}>Получить код</button>
				<div id="statusBox" className="alert alert-info" style={{display: "none"}}>
				</div>
				<div id="infoBox" className="alert alert-info">
					Код из 4х цифр отправляется СМС-сообщением
				</div>
			</Tab>
			</Tabs>
			<div id="errorBox" className="alert alert-danger" style={{display: "none", marginTop: "10px"}}></div>
			<button className="btn btn-danger btn-block my-4">Войти</button>
		</form>	
	);
}

export default Login;
