import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavItem}  from 'react-bootstrap';


//import logo from './logo.svg';
import './App.css';
import Login from './Login.js';
import LoginCard from './LoginCard.js';
import Profile from './Profile.js';
import Register from './Register.js';
import Home from './Home.js';
import Feedback from './Feedback.js';
import logoLoza from './images/logoLoza.png';
import logoVinograd from './images/logoVinograd.png';
import logoBagira from './images/logoBagira.png';
//import { Router, Route, Switch } from 'react-router';
import { BrowserRouter as Router,
		Route,
		Redirect,
		Switch,
		useHistory,
		Link } from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
						
const homePage = "https://vinograd-loza.ru";
const BASE="https://vinograd-loza.ru";


function App() {

	const [loading, setLoading] = useState(true);
	const [loggedIn, setLoggedIn] = React.useState(false);
	const [userData, setUserData] = useState({
		"card":"",
		"phone":"",

		"locality": "",

		"birthday": "",
		"email":"",
		"name":"",
		"surname":"",
		"patronymic":"",
		"sex":"",
		"civilStatus":"",
		"childCount":"",
		"completeness":"",
		"clv":"",
	});

	const PrivateRoute = ({ component: Component, ...rest }) => (
	  <Route {...rest} render={(props) => (
		props.loggedIn
		  ? <Component {...props} />
		  : <Redirect to='/login' />
	  )} />
	);

	useEffect(() => {
		console.log('App Effect')
		if(localStorage.getItem("cardAuthToken")){
			setLogged(true);
		}
    }, []);

	function getLoggedIn(){
		return loggedIn;
	}
	function setLogged(val){
		setUserData({loading: true});
		if(val){//authed. request Profile
			console.log('requesting profile');
			fetch(BASE+'/api/profile',{
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Bearer '+localStorage.getItem("cardAuthToken"),
					'Accept': 'application/json',
				}),
			})
			.then(function (res) {
				return res.json()
			})
			.then(function (data) {
				console.log(data);
				if(data.error){
					if(data.redirect=="/login"){
						setLogged(false);
					}
				}
				if(data.childDates){
					for(var i=0;i<data.childDates.length;++i){
						var k = "childDate"+i;
						var v = data.childDates[i];
						data[k]=v;
					}
					delete data['childDates'];
				}
				data.loading = false;
				console.log("setting userData");
				setUserData(data);
				setLoggedIn(val);
				/*
				if(data.completeness<0.8){//0.5?
					history.push("/profile");
				}
				*/
			}).catch(function (err){
				console.log(err);
				setUserData({loading:false});
			});
			
		}else{
			setUserData({});
		}
		console.log('setting loggedin '+val)
		setLoggedIn(val);
	}

  return (
	<Router history={history}>
		<Navbar bg="light" className="justify-content-between" >
		  <a className="navbar-brand" href={homePage}>
			  <img src={logoLoza}/>
			  <img src={logoVinograd}/>
			  <img src={logoBagira}/>
		  </a>
		  <Nav>
		  </Nav>
		  <Nav>
			{loggedIn ? 
				<>
				<Link className="nav-link" to="/profile">Анкета</Link>
				<Link className="nav-link" to="/logout">Выйти</Link>
				</>
			:
				<>
				<Link className="nav-link" to="/login">Войти</Link>
				<Link className="nav-link" to="/register">Регистрация</Link>
				</>
			}
			</Nav>
		</Navbar>
		<Switch>
			<Route exact path="/" render={(props) => <Home {...props} userData={userData} getLoggedIn={getLoggedIn} />} />
			<Route path="/logout" render={(props) => <Logout {...props} setLoggedIn={setLogged} getLoggedIn={getLoggedIn}/>} />
			<Route path="/login" render={(props) => <Login {...props} setLoggedIn={setLogged} getLoggedIn={getLoggedIn} />} />
			<Route path="/login_card" render={(props) => <LoginCard {...props} setLoggedIn={setLogged} getLoggedIn={getLoggedIn} />} />
			<Route path="/register" render={(props) => <Register {...props} setLoggedIn={setLogged} getLoggedIn={getLoggedIn}/>} />
			<Route path="/feedback" component={Feedback} />
			<Route path="/profile" render={(props) => <Profile {...props} userData={userData} getLoggedIn={getLoggedIn} setUserData={setUserData}/>} />
		</Switch>
	</Router>
  );
}
function Logout(props){
	localStorage.removeItem("cardAuthToken");
	props.setLoggedIn(false);
	window.location = homePage;
	//return null;
}


export default App;
